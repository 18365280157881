import * as React from 'react';
import {PageBackground} from '../components/PageBackground';
import {PageHeader} from '../components/PageHeader';
import {VSpacer} from '../components/VSpacer';
import {Images} from '../images/Images';
import {HAlign, HAligner} from '../components/HAligner';
import {Text, TextSize, TextWeight} from '../components/Text';
import {PageFooter} from '../components/PageFooter';
import {PictureFrame} from '../components/PictureFrame';
import {CareersLink} from "../components/CareersLink";

// declare var Calendly:any;

export class CompanyPage2 extends React.Component<any, any>
{
    render()
    {
        return  <PageBackground>
                    <PageHeader/>
                    <VSpacer size={15}/>

                    <div className={'container'} style={{color:'black', border:'1px solid transparent'}}>
                        <div className={'row'} style={{marginBottom:'20px'}}>
                            <div className={'col-sm-12'}>

                                <Text isHighlight={false} size={TextSize.h2} weight={TextWeight.w500}>
                                    About Us
                                </Text>
                                <VSpacer size={20}/>
                                <Text isHighlight={false} size={TextSize.h3} weight={TextWeight.w300}>
                                    Our mission is to build high quality software for a fraction of the price, all the while delighting our customers with the level of innovation and unique thinking we provide. Our customers are at the forefront of our mission and we strive hard to excel across all technical disciplines.
                                </Text>
                            </div>
                        </div>
                    </div>

                    <div className={'container'} style={{color:'white', border:'10px solid white',
                                                         backgroundColor: '#3F4553', padding:'30px', marginBottom: '30px'}}>
                        <div className={'row'} style={{marginBottom:'0px'}}>

                            <div className={'col-sm-12'}>
                                <Text color={'white'} isHighlight={false} size={TextSize.h2} weight={TextWeight.w500}>
                                    Our Approach
                                    <br/>
                                    <br/>
                                </Text>

                                <div>
                                    <Text color={'white'} isHighlight={false} size={TextSize.h2} weight={TextWeight.w500}>
                                        "&nbsp;
                                    </Text>
                                    <Text color={'white'} isHighlight={false} size={TextSize.h3} weight={TextWeight.w300}>
                                        Our approach is rooted in working hard, standing by our customers, doing what's best for them first and foremost, and hiring the best technical staff we can find. Together, as a unified team, we strive to serve our customers with the highest level of satisfaction, transparency, innovation, and ingenuity. We also value feedback from all customers and staff and strive to provide the foundation for transparent communication upon which everything else in our work practice resides.
                                    </Text>
                                    <Text color={'white'} isHighlight={false} size={TextSize.h2} weight={TextWeight.w500}>
                                        &nbsp;"
                                    </Text>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className={'container'} style={{color:'black', border:'1px solid transparent'}}>
                        <div className={'row'} style={{marginTop:'10px', marginBottom:'20px'}}>
                            <div className={'col-sm-6'}>
                                <Text isHighlight={false} size={TextSize.h2} weight={TextWeight.w500}>
                                    Our Staff
                                </Text>
                                <VSpacer size={20}/>
                                <Text isHighlight={false} size={TextSize.h3} weight={TextWeight.w300}>
                                    As a team of technical engineering experts, software developers, and software testers, our passion lies at the intersection of innovation and practicality.
                                    <br/><br/>
                                    We strive to work within this intersection to deliver projects at the greatest speed possible while working hard to never sacrifice on quality.
                                    <br/><br/>
                                    Every member of our team is actively developing their skill sets and look forward to applying them on their next customer facing projects.
                                    <br/><br/>
                                </Text>
                            </div>
                            <div className={'col-sm-6'}>
                                <PictureFrame>
                                    <img src={Images.Office14}/>
                                </PictureFrame>
                            </div>
                        </div>
                    </div>

                    <div className={'container'} style={{color:'black', border:'1px solid transparent'}}>
                        <div className={'row'} style={{marginTop:'10px',marginBottom:'20px'}}>
                            <div className={'col-sm-6'}>
                                <PictureFrame>
                                    <img src={Images.Office10}/>
                                </PictureFrame>
                            </div>
                            <div className={'col-sm-6'}>
                                <Text isHighlight={false} size={TextSize.h3} weight={TextWeight.w300}>
                                    The resulting atmosphere is one where we're incredibly supportive of each other, always helping fellow staff learn new technical knowledge, skills, and abilities. Through this KSA improvement we progress the careers of not only our staff but also our customers when we transfer this technical knowledge on our customer engagements.
                                    <br/><br/>
                                    In addition to our fantastic office, we have what we consider to be a friendly, family-like atmosphere where everyone is comfortable and is encouraged to participate and contribute to all of our technical challenges that arise.
                                </Text>
                            </div>

                        </div>
                    </div>


                    <div className={'container'} style={{color:'black', border:'1px solid transparent'}}>
                        <div className={'row'} style={{marginBottom:'20px'}}>
                            <div className={'col-sm-6'}>
                                <Text isHighlight={false} size={TextSize.h2} weight={TextWeight.w500}>
                                    Our Culture
                                </Text>
                                <VSpacer size={20}/>
                                <Text isHighlight={false} size={TextSize.h3} weight={TextWeight.w300}>
                                    For our staff, the right balance between work and family time is important. We work hard to maintain this balance while still serving our customers and partners in the most efficient way possible.
                                </Text>
                                <VSpacer size={20}/>
                                <Text isHighlight={false} size={TextSize.h3} weight={TextWeight.w300}>
                                    Our staff is multi-talented and often enjoys the great outdoors, the theater, and many other unique activities.
                                </Text>
                                <VSpacer size={20}/>
                                <Text isHighlight={false} size={TextSize.h3} weight={TextWeight.w300}>
                                    Come visit us in the office to see what it's truly like to work on our team.
                                </Text>
                            </div>
                            <div className={'col-sm-6'}>
                                <PictureFrame>
                                    <img src={Images.Office8}/>
                                </PictureFrame>
                            </div>
                        </div>
                    </div>

                    <div className={'container'} style={{color:'black', border:'1px solid transparent'}}>
                        <div className={'row'} style={{marginTop:'20px'}}>
                            <div className={'col-sm-8'}>
                                <PictureFrame>
                                    <img src={Images.CompanyAddress}/>
                                </PictureFrame>
                            </div>
                            <div className={'col-sm-4'}>
                                <Text isHighlight={false} size={TextSize.h2} weight={TextWeight.w500}>
                                    Careers
                                </Text>
                                <VSpacer size={25}/>

                                <Text size={TextSize.h3} weight={TextWeight.w300} padding={'0px 0px 20px 0px'} spacing={1} lineHeight={1.3}>
                                    We're always looking for fantastic engineers to join our team.
                                    <br/><br/>
                                    Please email <CareersLink/> with your CV and we'll email you to discuss next steps.
                                </Text>


                            </div>
                        </div>
                        <div className={'row'}>
                            <div className={'col-sm-12'} style={{marginTop:'5px'}}>
                                <VSpacer size={25}/>
                                <HAligner align={HAlign.Center}>
                                    <Text size={TextSize.h2} spacing={0.9}>
                                        Let's create the future together.
                                    </Text>
                                </HAligner>
                                <VSpacer size={25}/>
                            </div>

                        </div>
                    </div>


                    <PageFooter />


                </PageBackground>;
    }
}

export default () => <CompanyPage2/>;






